import { combineReducers } from "redux";
import { messageReducer } from "@agricircle/shared/redux";
import {farmsReducer } from "@agricircle/shared/farms/redux";
import soil from "./soil";

export const reducer = combineReducers({
    messages: messageReducer,
    farms: farmsReducer,
    soil
});

export const RootState = reducer;
