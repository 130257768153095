
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../authContext";
import { selectJobs } from "../redux/selectors";

export default () => {
    const { profile } = useAuthContext();
    const { jobId } = useParams();
    const jobs = useSelector(selectJobs);

    if (!jobs) return null;
    const job = jobs.find(j => j.id === jobId);
    if (!job) return "Job not found";

    if (profile.organization.type === "sampler")
        return <SamplerJob job={job} />
    if (profile.organization.type === "lab")
        return <LabJob job={job} />
    return <AdvisorJob job={job} />
};
