import { Route, Routes } from 'react-router-dom';
import { Stack, Typography } from "@mui/material";
import JobList from "./components/JobList";
import Job from "./components/Job";


export default () => {
    return <Stack>
        <Typography variant="h5">Precision Sampling</Typography>
        <Routes>
            <Route path="job:jobId" element={<Job />} />
            <Route path="*" element={<JobList />} />
            <JobList />
        </Routes>
    </Stack>
};
