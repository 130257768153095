import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "@agricircle/shared";
import { displayErrorMessage } from "@agricircle/shared/redux";
import { useAsyncApi } from "@agricircle/shared/hooks";
import { useLoadFarms } from "@agricircle/shared/farms/hooks";
import { selectMode } from "../redux/soil/selectors";

export const loadSoil = () => {
    const { profile } = useAuthContext();
    const mode = useSelector(selectMode);

    useLoadFarms(profile?.id, mode === "edit", { extraAttributes: ["soil", "map_metadata"] });
};


export const useSoilApi = () => {
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();


    ////////////////// Get soil property meta
    const getSoilPropertyMeta = async (fieldId, soilProperty, callback) => {
        const result = await sendRequest("GET", `fields/${fieldId}/soil/properties/${soilProperty}`);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to get soil property meta", result.data));
        else if (callback)
            callback(result.data);
        return result;
    };

    ////////////////// Compute properties
    const computeSoil = async (fieldId, callback) => {
        const result = await sendRequest("POST", `fields/${fieldId}/compute_soil?recalculate`);
        if (result.isError && result.status !== 422)
            dispatch(displayErrorMessage("Failed to compute soil properties", result.data));
        else if (callback)
            callback(result.data);
        return result;
    };

    ////////////////// Get yield performance meta
    const getYieldPerformanceMeta = async (fieldId, callback) => {
        const result = await sendRequest("GET", `fields/${fieldId}/yield/performance`);
        if (result.isError && result.status !== 422)
            dispatch(displayErrorMessage("Failed to compute soil properties", result.data));
        else if (callback)
            callback(result.data, result.status === 202);
        return result;
    };


    ////////////////// Compute yield performance
    const computeYieldPerformance = async (fieldId, callback) => {
        const result = await sendRequest("POST", `fields/${fieldId}/yield/performance`);
        if (result.isError && result.status !== 422)
            dispatch(displayErrorMessage("Failed to compute yield performance", result));
        else if (callback)
            callback(result.data, result.status === 202);
        return result;
    };

    return { getSoilPropertyMeta, computeSoil, getYieldPerformanceMeta, computeYieldPerformance };
};
