export const LAYERS = [
    {
        id: "performance",
        type: "yield",
        hasClasses: true,
        info: {
            units: "Class",
            ranges: [{ min: "Low", colorMin: "#c30010" }, { min: "Medium", colorMin: "#009bd6" }, { min: "High", colorMin: "#3b8132" }]
        }
    },
    {
        id: "clay",
        type: "soil/properties",
        info: {
            units: "%",
            ranges: [{ min: "5", max: "13", colorMin: "#fbeeda", colorMax: "#f7e8b1" }, { min: "13", max: "21", colorMin: "#f7e8b1", colorMax: "#bb9457" }, { min: "21", max: "29", colorMin: "#bb9457", colorMax: "#9f7f52" }, { min: "29", max: "37", colorMin: "#9f7f52", colorMax: "#826a4c" }, { min: "37", max: "45+", colorMin: "#826a4c", colorMax: "#484041" }]
        }
    },
    {
        id: "silt",
        type: "soil/properties",
        info: {
            units: "%",
            ranges: [{ min: "20", max: "32", colorMin: "#fbeeda", colorMax: "#f7e8b1" }, { min: "32", max: "44", colorMin: "#f7e8b1", colorMax: "#bb9457" }, { min: "44", max: "56", colorMin: "#bb9457", colorMax: "#9f7f52" }, { min: "56", max: "68", colorMin: "#9f7f52", colorMax: "#826a4c" }, { min: "68", max: "80+", colorMin: "#826a4c", colorMax: "#484041" }]
        }
    },
    {
        id: "sand",
        type: "soil/properties",
        info: {
            units: "%",
            ranges: [{ min: "20", max: "32", colorMin: "#fbeeda", colorMax: "#f7e8b1" }, { min: "32", max: "44", colorMin: "#f7e8b1", colorMax: "#bb9457" }, { min: "44", max: "56", colorMin: "#bb9457", colorMax: "#9f7f52" }, { min: "56", max: "68", colorMin: "#9f7f52", colorMax: "#826a4c" }, { min: "68", max: "80+", colorMin: "#826a4c", colorMax: "#484041" }]
        }
    },
    {
        id: "humus",
        type: "soil/properties",
        info: {
            units: "%",
            ranges: [{ min: "1.5", max: "2.4", colorMin: "#577590", colorMax: "#43aa8b" }, { min: "2.4", max: "3.3", colorMin: "#43aa8b", colorMax: "#90be6d" }, { min: "3.3", max: "4.2", colorMin: "#90be6d", colorMax: "#f9c74f" }, { min: "4.2", max: "5.1", colorMin: "#f9c74f", colorMax: "#f8961e" }, { min: "5.1", max: "6+", colorMin: "#f8961e", colorMax: "#f94144" }]
        }
    },
    {
        id: "ph",
        type: "soil/properties",
        hasClasses: true,
        info: {
            units: "Class",
            ranges: [{ min: "A", colorMin: "#d00000" }, { min: "B", colorMin: "#faa307" }, { min: "C", colorMin: "#38b000" }, { min: "D", colorMin: "#90e0ef" }, { min: "E", colorMin: "#023e8a" }]
        },
        physical_info: {
            units: "pH",
            ranges: [{ min: "4", max: "4.8", colorMin: "#fc5219", colorMax: "#fea500" }, { min: "4.8", max: "5.6", colorMin: "#fea500", colorMax: "#6ed700" }, { min: "5.6", max: "6.4", colorMin: "#6ed700", colorMax: "#01c0b9" }, { min: "6.4", max: "7.2", colorMin: "#01c0b9", colorMax: "#004bcb" }, { min: "7.2", max: "8+", colorMin: "#004bcb", colorMax: "#442d81" }]
        }
    },
    {
        id: "phosphorus",
        type: "soil/properties",
        hasClasses: true,
        info: {
            units: "Class",
            ranges: [{ min: "A", colorMin: "#d00000" }, { min: "B", colorMin: "#faa307" }, { min: "C", colorMin: "#38b000" }, { min: "D", colorMin: "#90e0ef" }, { min: "E", colorMin: "#023e8a" }]
        }
    },
    {
        id: "potassium",
        type: "soil/properties",
        hasClasses: true,
        info: {
            units: "Class",
            ranges: [{ min: "A", colorMin: "#d00000" }, { min: "B", colorMin: "#faa307" }, { min: "C", colorMin: "#38b000" }, { min: "D", colorMin: "#90e0ef" }, { min: "E", colorMin: "#023e8a" }]
        }
    },
    {
        id: "magnesium",
        type: "soil/properties",
        hasClasses: true,
        info: {
            units: "Class",
            ranges: [{ min: "A", colorMin: "#d00000" }, { min: "B", colorMin: "#faa307" }, { min: "C", colorMin: "#38b000" }, { min: "D", colorMin: "#90e0ef" }, { min: "E", colorMin: "#023e8a" }]
        }
    },
    {
        id: "disabled_calcium",
        type: "soil/properties",
        hasClasses: true,
        info: {
            units: "Class",
            ranges: [{ min: "A", colorMin: "#d00000" }, { min: "B", colorMin: "#faa307" }, { min: "C", colorMin: "#38b000" }, { min: "D", colorMin: "#90e0ef" }, { min: "E", colorMin: "#023e8a" }]
        }
    },
    {
        id: "disabled_boron",
        type: "soil/properties",
        hasClasses: true,
        info: {
            units: "Class",
            ranges: [{ min: "A", colorMin: "#d00000" }, { min: "B", colorMin: "#faa307" }, { min: "C", colorMin: "#38b000" }, { min: "D", colorMin: "#90e0ef" }, { min: "E", colorMin: "#023e8a" }]
        }
    },
    {
        id: "disabled_copper",
        type: "soil/properties",
        hasClasses: true,
        info: {
            units: "Class",
            ranges: [{ min: "A", colorMin: "#d00000" }, { min: "B", colorMin: "#faa307" }, { min: "C", colorMin: "#38b000" }, { min: "D", colorMin: "#90e0ef" }, { min: "E", colorMin: "#023e8a" }]
        }
    },
    {
        id: "disabled_sodium",
        type: "soil/properties",
        hasClasses: true,
        info: {
            units: "Class",
            ranges: [{ min: "A", colorMin: "#d00000" }, { min: "B", colorMin: "#faa307" }, { min: "C", colorMin: "#38b000" }, { min: "D", colorMin: "#90e0ef" }, { min: "E", colorMin: "#023e8a" }]
        }
    },
    {
        id: "disabled_sulfur",
        type: "soil/properties",
        hasClasses: true,
        info: {
            units: "Class",
            ranges: [{ min: "A", colorMin: "#d00000" }, { min: "B", colorMin: "#faa307" }, { min: "C", colorMin: "#38b000" }, { min: "D", colorMin: "#90e0ef" }, { min: "E", colorMin: "#023e8a" }]
        }
    },
    {
        id: "disabled_zinc",
        type: "soil/properties",
        hasClasses: true,
        info: {
            units: "Class",
            ranges: [{ min: "A", colorMin: "#d00000" }, { min: "B", colorMin: "#faa307" }, { min: "C", colorMin: "#38b000" }, { min: "D", colorMin: "#90e0ef" }, { min: "E", colorMin: "#023e8a" }]
        }
    },
    {
        id: "carbon_nitrogen",
        type: "soil/properties",
        info: {
            units: "",
            ranges: [{ min: "0", max: "4", colorMin: "#577590", colorMax: "#43aa8b" }, { min: "4", max: "8", colorMin: "#43aa8b", colorMax: "#90be6d" }, { min: "8", max: "12", colorMin: "#90be6d", colorMax: "#f9c74f" }, { min: "12", max: "16", colorMin: "#f9c74f", colorMax: "#f8961e" }, { min: "16", max: "20+", colorMin: "#f8961e", colorMax: "#f94144" }]
        }
    },
    {
        id: "nitrogen_mineralization",
        type: "soil/properties",
        info: {
            units: <><sup>mg N</sup>&frasl;<sub>kg</sub></>,
            ranges: [{ min: "0", max: "20", colorMin: "#577590", colorMax: "#43aa8b" }, { min: "20", max: "40", colorMin: "#43aa8b", colorMax: "#90be6d" }, { min: "40", max: "60", colorMin: "#90be6d", colorMax: "#f9c74f" }, { min: "60", max: "80", colorMin: "#f9c74f", colorMax: "#f8961e" }, { min: "80", max: "100", colorMin: "#f8961e", colorMax: "#f94144" }]
        }
    },
    {
        id: "soc",
        type: "soil/properties",
        info: {
            units: "tons",
            unit_conversion_factor: 1000,
            ranges: [{ min: "20-", max: "40", colorMin: "#FFFF92", colorMax: "#FAEA81" }, { min: "40", max: "60", colorMin: "#FAEA81", colorMax: "#EFC55F" }, { min: "60", max: "80", colorMin: "#EFC55F", colorMax: "#D89842" }, { min: "80", max: "100", colorMin: "#D89842", colorMax: "#9C5123" }, { min: "100", max: "120+", colorMin: "#9C5123", colorMax: "#610F08" }]
        }
    }
];
