
import { useSelector } from "react-redux";
import { List } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/NavigateNext";
import { selectFarm } from "../../farms/redux/selectors";
import { selectJobs } from "../redux/selectors";

export default () => {
    const farm = useSelector(selectFarm);
    const jobs = useSelector(selectJobs);

    if (!farm || !jobs) return null;

    if (!jobs.length) return "No jobs yet";

    return (<List>
        {jobs.map(job => (<ListItem secondaryAction={<IconButton href={`job/${job.id}`}><ArrowRightIcon /></IconButton>}>
            <ListItemText primary={job.updated_at} secondary={job.status} />
        </ListItem>))}
    </List>);
};
